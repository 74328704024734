<template>

  <li class="nav-item" v-if="!positionKidsHas">
    <router-link
        :to="positionLink"
        class="nav-link"
        :class="{'menu-link': depth===0}"
        :data-key="position.code"
        v-html="positionName"
    ></router-link>
  </li>
  <li class="nav-item" v-if="positionKidsHas">
    <a
        class="nav-link menu-link"
        v-html="positionName"
        :href="'#'+position.code"
        data-bs-toggle="collapse"
        v-if="!positionLinkHas"
    ></a>
    <router-link
        class="nav-link menu-link"
        v-html="positionName"
        v-if="positionLinkHas"
        :to="positionLink"
    ></router-link>
    <div class="menu-dropdown collapse" :id="position.code" v-if="positionKidsHas">
      <ul class="nav nav-sm flex-column">
        <MenuItems :position="positionKid" :positions="positions" :depth="depth+1"
                   v-for="positionKid in positionKids"/>
      </ul>
    </div>
    <a class="menu-dart collapsed"
       :class="dartCls"
       :href="'#'+position.code"
       data-bs-toggle="collapse"
       role="button"
       aria-expanded="false"
       :aria-controls="position.code"></a>
  </li>
</template>


<script>

export default {

  name: "MenuItems",
  props: {
    position: {
      default() {
        return {}
      }
    },
    depth: {
      default() {
        return 0
      }
    },
    positions: {
      default() {
        return {}
      }
    },
  },
  computed: {
    dartCls() {
      return ['menu-dart--' + this.depth];
    },
    positionKids() {
      return this.positions.filter(element => element.code_top === this.position.code);
    },
    positionKidsCount() {
      return this.positionKids.length;
    },
    positionKidsHas() {
      return this.positionKidsCount > 0;
    },
    positionLink() {
      return this.position.link ? this.position.link : '/';
    },
    positionLinkHas() {
      return !!this.position.link;
    },
    positionName() {
      let name = this.position.name;
      let icon = "";

      if (this.position.icon === true)
        icon = '<i class="bx bxs-dashboard"></i>';

      if (this.position.icon !== true && this.position.icon === true)
        icon = '<i class="' + this.position.icon + '"></i>';

      if (this.position.icon !== true && this.position.icon_google)
        icon = '<i class="material-symbols-outlined">' + this.position.icon_google + '</i>';

      if (this.depth === 0)
        name = '<span>' + name + '</span>';

      return icon + name;

    }
  },
};
</script>


<style lang="scss">
.nav-item {
  position: relative;
}

.menu-dart {
  position: absolute;

  width: 32px;
  height: 32px;
  top: 0.425rem;
  right: 0.425rem;

  cursor: pointer;

  &--1 {
    top: 0.15rem;
  }

  &:before {
    content: '';
    position: absolute;

    width: 8px;
    height: 8px;

    border-top: 2px solid var(--sp-vertical-menu-item-color-dark);
    border-right: 2px solid var(--sp-vertical-menu-item-color-dark);

    transform: rotate(-135deg);
    transition: .3s;
    right: 10px;
    top: 12px;
  }
}

.menu-dart:not(.collapsed) {
  //background-color: yellow;
  &:before {
    transform: rotate(-225deg);
  }
}

.menu-dart:hover {
  //background-color: yellow;
  &:before {
    border-top: 2px solid white;
    border-right: 2px solid white;
  }
}

[data-layout=vertical][data-sidebar-size=sm] .menu-dart {
  display: none !important;
}

[data-layout="horizontal"] .menu-dart {
  display: none;
}


</style>