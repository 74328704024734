<template>

  <draggable
      tag="ul"
      :list="gallery"
      :group="{ name: 'g1' }"
      item-key="filename"
      :disabled="!move"
      @end="$emit('actionUpdateLocation', gallery)"
      class="gallery">
    <template #item="{ element }">
      <li class="gallery__item image">

        <span class="image__thumb" :style="{ backgroundImage: `url(${element.thumb})` }"></span>

        <div class="image__buttons" v-if="!element.progress || element.progress == 100.00">

          <button
              class="image__buttons-item image__buttons-delete"
              @click.prevent="this.$emit('imageDelete', element)"
              v-if="can_delete"
          >
            <img src="@/assets/img/trash.svg"/>
          </button>
          <button
              class="image__buttons-item image__buttons-edit"
              @click.prevent="this.$emit('imageEdit', element)"
              v-if="can_edit_info"
          >
            <img src="@/assets/img/pencil.png"/>
          </button>
          <button
              class="image__buttons-item image__buttons-show"
              @click="showGallery(element.thumb)"
              v-if="can_zooming"
          >
            <img src="@/assets/img/search.svg"/>
          </button>
        </div>
        <div class="image__progress" v-else>
          <div class="progress" v-if="element.progress">
            <span class="progress__load" :style="{ width: `${element.progress}%` }"></span>
          </div>
        </div>

      </li>
    </template>
  </draggable>


  <a v-if="download_all_gallery_url && gallery.length" @click="downloadAll" ref="downloadAllBtn"
     class="gallery__download">
    Pobierz wszystkie pliki
  </a>


  <vue-easy-lightbox
      :visible="gallery_visible"
      :imgs="gallery_imgs"
      :index="gallery_index"
      @hide="hideGallery"
  >

  </vue-easy-lightbox>
</template>

<script>
import {useToast} from "vue-toastification";
import CoreDate from "@/Library/Helpers/date";
import VueEasyLightbox from 'vue-easy-lightbox'
import axios from "@/axios";
import draggable from 'vuedraggable';

export default {
  name: "ShowGallery",
  emits: ['imageDelete', 'imageEdit'],
  components: {
    VueEasyLightbox,
    draggable
  },
  props: {
    class: {},
    style: {},
    gallery: {},
    can_delete: {},
    can_edit_info: {},
    can_zooming: {},
    download_all_gallery_url: {},
    move: {}
  },
  data() {
    return {
      gallery_imgs: '', // Img Url , string or Array of string
      gallery_visible: false,
      gallery_index: 0 // default: 0
    }
  },
  methods: {

    async downloadAll() {

      let toast = useToast();

      let btn = this.$refs.downloadAllBtn;

      btn.style.opacity = .3;

      await axios
          .get(this.download_all_gallery_url)
          .then(await function (response) {

            toast.success("Pobieranie rozpoczęte", {
              timeout: 2000
            });
            let ret = response.data.data.url;

            btn.style.opacity = 1;

            window.location = ret;
          });


    },

    imageSizeFormat(size) {

      let ret = size;
      if (Number.isInteger(size)) {

        let i = Math.floor(Math.log(size) / Math.log(1024));
        ret = (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
      }

      return ret;

    },

    showDate(date) {
      return CoreDate.show(date);
    },
    showGallery(img) {

      if (this.can_zooming) {
        this.gallery_imgs = img
        this.gallery_visible = true
      }
    },
    hideGallery() {
      this.gallery_visible = false
    }
  }
}
</script>

<style scoped lang="scss">


.progress {

  position: absolute;

  left: 0;
  right: 0;
  height: 4px;
  top: 0;
  overflow: hidden;
  box-sizing: border-box;

  &__load {
    //height: 4px;
    background-color: #3ea5fe;
    position: absolute;


    top: 0px;
    left: 0;
    bottom: 0;

    will-change: width;

    transition: .5s;

  }

}


.gallery {

  margin: 0;
  padding: 0;

  list-style: none;

  display: grid;
  grid-template-columns: repeat( auto-fill, minmax(300px, 1fr) );
  column-gap: 15px;

  &__item {
    width: 100%;
    height: 0;
    padding-bottom: 67%;
    margin-bottom: 12px;

    position: relative;
  }

  &__download {
    cursor: pointer;
  }

}

.image {
  //display: flex;
  //flex-direction: row;
  //width: 100%;
  //margin: 0 0 12px;
  //padding: 0;
  //
  //position: absolute;
  //
  //left: 0;
  //top: 0;
  //right: 0;
  //bottom: 0;
  //overflow: hidden;

  &__thumb {
    overflow: hidden;

    position: absolute;

    display: block;

    width: 100%;

    border-radius: 6px;

    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    background-size: cover;
    background-position: center;
    border: 1px solid #e6e7ea;
  }

  &__info {
    padding: 2px 0 0 12px;

    display: flex;
    flex-direction: column;

    text-align: left;

    flex: 1 0;
    overflow: hidden;

  }

  &__buttons {

    top: 0;
    right: 12px;

    position: absolute;
    width: 120px;
    min-width: 120px;

    text-align: center;

    margin-top: 12px;

    &-item {
      border-color: transparent;

      display: inline-block;

      cursor: pointer;

      //margin-left: 12px;

      float: right;

      width: 30px;
      height: 30px;

      border-radius: 50%;

      padding: 0;


      margin-right: 6px;


      background-color: rgba(255, 255, 255, 1);
      user-select: none;

      & img {
        //margin-top: 4px;

      }

      &:first-of-type {
        margin-left: 0;
      }
    }

    &-delete {
      color: red;
    }

    &-edit {
      //color: red;

      & img {
        width: 20px;

      }

    }

    &-show {
      //color: red;

      & img {
        width: 20px;

      }

    }
  }

  &__progress {
    width: 100%;

    .progress {
      top: 0;

      //border-radius: 4px;
      height: 8px;
      overflow: hidden;
      background: #e6e7ea;
      margin: 0;
      padding: 0;

      &__load {
        float: left;
        top: -4px;
        height: 12px;
        margin: 0;
        padding: 0;
      }
    }
  }

  &__name {
    color: #3f424a;
    height: 21px;
    overflow: hidden;
  }

  &__size {
    color: #898f99;

  }

  &__date {
    color: #898f99;

  }
}


.gallery {
  &__item {
  }

}

</style>